<template>
  <div class="my-second usdt-list ">
      <Head :title="$t('usdt.usdt')" :show="true" :bg="true" />
      
      <div class="usdt-main" v-if="wallet.all">
          <div class="amount-box">
              <p class="psmall">{{$t('usdt.allamount')}}</p>
              <p>{{wallet.all.usable_balance | number_format}} USD</p>
          </div>
          <img class="usdt-bg" :src="require('@/assets/imgs/usdt-bg.png')" alt="">
          <div class="usdt-box">
              <div class="usdt-box-item" @click.stop="gowhere('usdt-index',wallet.usdt)">
                  <div class="item-title">
                      <div class="item-title-left">
                          <img :src="require('@/assets/imgs/usdt.png')" class="left-img" alt="">
                          <span class="span" >USDT</span>
                          <van-button class="usdt-btn color-usdt" @click.stop="gotowhere('usdt-recharge')">{{$t('common.buy')}}</van-button>
                      </div>
                      <div class="item-title-right">
                          
                          <i class="iconfont iconfanhui2 go-icon"></i>
                      </div>
                  </div>
                  <div class="item-number">
                      <div class="item-number-left">
                          <p class="item-key">{{$t('auction.quantity')}}</p>
                          <p  class="item-value">{{wallet.usdt.usable_balance | number_format}}</p>
                      </div>
                      <div class="item-number-right">
                          <p class="item-key">{{$t('usdt.zhehe')}} USD</p>
                          <p class="item-value">{{wallet.usdt.usable_balance_usdt | number_format}}</p>
                      </div>
                  </div>
              </div>
              <div class="usdt-box-item"  @click="gowhere('usdt-index',wallet.usdc)">
                  <div class="item-title">
                      <div class="item-title-left">
                          <img :src="require('@/assets/imgs/usdc.png')" class="left-img" alt="">
                          <span class="span" >USDC</span>
                          <van-button class="usdt-btn color-usdc" @click.stop="gotowhere('usdt-recharge')">{{$t('common.buy')}}</van-button>
                      </div>
                       <div class="item-title-right">
                          
                          <i class="iconfont iconfanhui2 go-icon"></i>
                      </div>
                  </div>
                  <div class="item-number">
                      <div class="item-number-left">
                          <p class="item-key">{{$t('auction.quantity')}}</p>
                          <p  class="item-value">{{wallet.usdc.usable_balance | number_format}}</p>
                      </div>
                      <div class="item-number-right">
                          <p class="item-key">{{$t('usdt.zhehe')}} USD</p>
                          <p class="item-value">{{wallet.usdc.usable_balance_usdt | number_format}}</p>
                      </div>
                  </div>
              </div>
              <div class="usdt-box-item"  @click="gowhere('usdt-index',wallet.eth)">
                  <div class="item-title">
                      <div class="item-title-left">
                          <img :src="require('@/assets/imgs/eth.png')" class="left-img" alt="">
                          <span class="span" >ETH</span>
                          <van-button class="usdt-btn color-eth" @click.stop="gotowhere('usdt-recharge')">{{$t('common.buy')}}</van-button>
                      </div>
                       <div class="item-title-right">
                          
                          <i class="iconfont iconfanhui2 go-icon"></i>
                      </div>
                  </div>
                  <div class="item-number">
                      <div class="item-number-left">
                          <p class="item-key">{{$t('auction.quantity')}}</p>
                          <p  class="item-value">{{wallet.eth.usable_balance | sixNumber}}</p>
                      </div>
                      <div class="item-number-right">
                          <p class="item-key">{{$t('usdt.zhehe')}} USD</p>
                          <p class="item-value">{{wallet.eth.usable_balance_usdt | number_format}}</p>
                      </div>
                  </div>
              </div>
              <div class="usdt-box-item"  @click="gowhere('usdt-index',wallet.dai)">
                  <div class="item-title">
                      <div class="item-title-left">
                          <img :src="require('@/assets/imgs/dai.png')" class="left-img" alt="">
                          <span class="span" >DAI</span>
                          <van-button class="usdt-btn color-dai" @click.stop="gotowhere('usdt-recharge')">{{$t('common.buy')}}</van-button>
                      </div>
                       <div class="item-title-right">
                          
                          <i class="iconfont iconfanhui2 go-icon"></i>
                      </div>
                  </div>
                  <div class="item-number">
                      <div class="item-number-left">
                          <p class="item-key">{{$t('auction.quantity')}}</p>
                          <p  class="item-value">{{wallet.dai.usable_balance | number_format}}</p>
                      </div>
                      <div class="item-number-right">
                          <p class="item-key">{{$t('usdt.zhehe')}} USD</p>
                          <p class="item-value">{{wallet.dai.usable_balance_usdt | number_format}}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template> 
<script>

export default {
    name: "usdt-list",
    data(){
        return {
            wallet:{}
        }
    },
    methods:{
        gowhere(name,item){
            this.$router.push({name,query:{
                current_id:item.current_id,
                item:JSON.stringify(item)
            }})
        },
        gotowhere(name){
            this.$router.push({
                name
            })
        },
    },
    mounted(){
        this.$ajax.walletmy()
        .then(res=>{
            if(res.code==200){
                var usdt = {}
                var usdc = {}
                var dai = {}
                var eth = {}
                var all = {}
                res.data.list.forEach(item=>{
                    if(item.current_id==1011){
                        usdt = {...item}
                    }
                    if(item.current_id==1020){
                        usdc = {...item}
                    }
                    if(item.current_id==1021){
                        dai = {...item}
                    }
                    if(item.current_id==1003){
                        eth = {...item}
                    }
                    if(item.current_id==-1){
                        all = {...item}
                    }
                })

                this.wallet = {
                    usdt,
                    usdc,
                    dai,
                    eth,
                    all
                }
            }
        })
    }
}
</script>